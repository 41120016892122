<template>
  <div class="bg">
    <img src="../assets/images/404.png" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
    };
  },
  components: {
  },

  created() {
   
  },
  methods: {                                                  

  },
};
</script>

<style lang="less" scoped>
.bg{
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
  }
}

</style>
